import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import i18n from "i18next";
import LoadingPanel from "../../../../loading_panel";
import { FETCH_FAMILY_PERFORMANCE } from "./graphql";
import PrivateRelativePerformanceTable from "Components/show/overview/PrivateRelativePerformanceTable";

const FamilyPerformance = ({ investmentId }: { investmentId: number }) => {
  const { data, loading } = useQuery(FETCH_FAMILY_PERFORMANCE, {
    variables: {
      id: investmentId,
    },
  });

  const investmentFamilyData = useMemo(() => {
    if (!data) return [];

    const familyInvestments =
      data.investment?.family?.investmentsFamily.map(x => x.investment) ?? [];
    const firmInvestments = data.investment?.firm?.investments?.items ?? [];

    let result;
    if (familyInvestments.length > 0) {
      result = familyInvestments;
    } else {
      result = firmInvestments;
    }
    //uniq by id
    return [data.investment, ...result].filter(
      (v, i, a) => a.findIndex(t => t?.id === v?.id) === i
    );
  }, [data]);

  return (
    <>
      <div className="summary-heading" id="performance">
        <div className="heading" data-cy="family-performance-heading">
          {i18n.t("overview.family_performance")}
        </div>
      </div>
      <div
        className="performance-statistics-table"
        data-cy="family-performance"
      >
        <PrivateRelativePerformanceTable
          statsGroupData={investmentFamilyData}
        />
      </div>
    </>
  );
};

export default FamilyPerformance;
