import { Table as AntdTable } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import InvestmentLink from "Components/InvestmentLink";
import { FundTag } from "Components/InvestmentTile/Body/fund_tag.js";
import { asDisplayDate } from "Helpers/index";
import { set } from "lodash";
import React, { useMemo } from "react";
import * as types from "_graphql-types/graphql";
import {
  displayCompoundReturnForSelectedRangeOffset,
  PeersStats,
} from "../PeerStatistics";
import Cell from "./Cell";

interface Params {
  investments?: NonNullable<
    types.PeersStatsQuery["investment"]["peersStats"]
  >["items"];
  statsGroup?: PeersStats;
  rangeOffsetLabel: string;
}

type PeerItemStats = {
  investment: {
    id: number;
    name?: string | null;
    isEntitled: boolean;
  };
  details: {
    list?: string;
    strategy?: string;
    subStrategy?: string;
  };
} & { [K in keyof PeersStats]: PeersStats[K][number] };

const Tag = ({
  title,
  extraClassName,
}: {
  title?: string | null;
  extraClassName?: string;
}) => {
  const tag = title;
  if (!tag) return <span>-</span>;
  return (
    <FundTag
      title={title}
      truncateWithInitials
      extraClassName={extraClassName}
    />
  );
};

// eslint-disable-next-line max-lines-per-function
function getTableColumns(
  data: PeerItemStats[],
  rangeOffsetLabel: string
): ColumnsType<PeerItemStats> {
  const focusedInvestment = data[0];
  const nameFilters: {
    filterMode: ColumnType<PeerItemStats>["filterMode"];
    filters: ColumnType<PeerItemStats>["filters"];
    onFilter: ColumnType<PeerItemStats>["onFilter"];
  } = {
    filterMode: "tree",
    filters: data.slice(1, -1).map(datum => ({
      text: datum.investment.name,
      value: datum.investment.id,
    })),
    onFilter: (value, record: PeerItemStats) => {
      if (record.investment.id === focusedInvestment.investment.id) return true;
      return record.investment.id === value;
    },
  };

  const detailFilters: {
    filterMode: ColumnType<PeerItemStats>["filterMode"];
    filters: ColumnType<PeerItemStats>["filters"];
    onFilter: ColumnType<PeerItemStats>["onFilter"];
  } = {
    filterMode: "tree",
    filters: Object.entries(
      data.reduce<{
        [key: string]: { [key: string]: { [key: string]: string } };
      }>((accu, datum) => {
        set(
          accu,
          [
            datum.details.strategy || "None",
            datum.details.subStrategy || "None",
            datum.details.list || "None",
          ],
          datum.details.list
        );
        return accu;
      }, {})
    ).map(([strategy, strategies]) => ({
      text: strategy,
      value: strategy,
      children: Object.entries(strategies).map(([subStrategy, lists]) => ({
        text: subStrategy,
        value: `${strategy || "None"}::${subStrategy || "None"}`,
        children: Object.values(lists).map(list => ({
          text: list,
          value: `${strategy || "None"}::${subStrategy || "None"}::${
            list || "None"
          }`,
        })),
      })),
    })),
    onFilter: (value, record: PeerItemStats) => {
      if (record.investment.id === focusedInvestment.investment.id) return true;

      const [strategy, subStrategy, list] = (value as string).split("::");

      return (
        (record.details.list || "None") === list &&
        (record.details.strategy || "None") === strategy &&
        (record.details.subStrategy || "None") === subStrategy
      );
    },
  };

  const intervalLabel = I18n.t(`analytics.peer_statistics.${rangeOffsetLabel}`);

  const compoundReturnAtInterval = {
    title: intervalLabel + " c.",
    key: `compoundReturnAtInterval`,
    width: 85,
    render: (_text: unknown, record: PeerItemStats) => (
      <Cell value={record.compoundReturn} />
    ),
  };

  return [
    {
      title: I18n.t("analytics.peer_statistics.name"),
      dataIndex: "investment",
      key: "investment",
      width: 200,
      render: (text, record) => {
        const { id, name, isEntitled } = record.investment;
        return (
          <InvestmentLink
            className="fund-link-wrapper"
            investmentId={id}
            isEntitled={isEntitled}
          >
            {name}
          </InvestmentLink>
        );
      },
      fixed: "left",
      ...nameFilters,
    },
    {
      title: I18n.t("analytics.peer_statistics.details"),
      dataIndex: "details",
      key: "details",
      width: 125,
      render: (text, record) => {
        const { list, strategy, subStrategy } = record.details;
        return (
          <>
            <Tag title={list} extraClassName="m-0" />
            <Tag title={strategy} extraClassName="m-0" />
            <Tag title={subStrategy} extraClassName="m-0" />
          </>
        );
      },
      ...detailFilters,
    },
    {
      title: I18n.t("analytics.peer_statistics.previous_month"),
      dataIndex: "compoundReturn1m",
      key: "compoundReturn1m",
      sorter: (a, b) =>
        (a.compoundReturn1m || -Infinity) - (b.compoundReturn1m || -Infinity),
      width: 85,
      render: (text, record) => <Cell value={record.compoundReturn1m} />,
    },
    {
      title: I18n.t("analytics.peer_statistics.3_m"),
      dataIndex: "compoundReturn3m",
      key: "compoundReturn3m",
      sorter: (a, b) =>
        (a.compoundReturn3m || -Infinity) - (b.compoundReturn3m || -Infinity),
      width: 85,
      render: (text, record) => <Cell value={record.compoundReturn3m} />,
    },
    {
      title: I18n.t("analytics.peer_statistics.ytd"),
      dataIndex: "compoundReturnYtd",
      key: "compoundReturnYtd",
      sorter: (a, b) =>
        (a.compoundReturnYtd || -Infinity) - (b.compoundReturnYtd || -Infinity),
      width: 85,
      render: (text, record) => <Cell value={record.compoundReturnYtd} />,
    },
    {
      title: I18n.t("analytics.peer_statistics.1_y"),
      dataIndex: "compoundReturn1yAnnualized",
      key: "compoundReturn1yAnnualized",
      sorter: (a, b) =>
        (a.compoundReturn1yAnnualized || -Infinity) -
        (b.compoundReturn1yAnnualized || -Infinity),
      width: 85,
      render: (text, record) => (
        <Cell value={record.compoundReturn1yAnnualized} />
      ),
    },
    {
      title: I18n.t("analytics.peer_statistics.3_y"),
      dataIndex: "compoundReturn3yAnnualized",
      key: "compoundReturn3yAnnualized",
      sorter: (a, b) =>
        (a.compoundReturn3yAnnualized || -Infinity) -
        (b.compoundReturn3yAnnualized || -Infinity),
      width: 85,
      render: (text, record) => (
        <Cell value={record.compoundReturn3yAnnualized} />
      ),
    },
    {
      title: I18n.t("analytics.peer_statistics.5_y"),
      dataIndex: "compoundReturn5yAnnualized",
      key: "compoundReturn5yAnnualized",
      sorter: (a, b) =>
        (a.compoundReturn5yAnnualized || -Infinity) -
        (b.compoundReturn5yAnnualized || -Infinity),
      width: 85,
      render: (text, record) => (
        <Cell value={record.compoundReturn5yAnnualized} />
      ),
    },
    ...(displayCompoundReturnForSelectedRangeOffset(rangeOffsetLabel)
      ? [compoundReturnAtInterval]
      : []),
    {
      title: I18n.t("analytics.peer_statistics.itd_anl"),
      dataIndex: "compoundReturnItdAnnualized",
      key: "compoundReturnItdAnnualized",
      sorter: (a, b) =>
        (a.compoundReturnItdAnnualized || -Infinity) -
        (b.compoundReturnItdAnnualized || -Infinity),
      width: 85,
      render: (text, record) => (
        <Cell value={record.compoundReturnItdAnnualized} />
      ),
    },
    {
      title: `${intervalLabel} ${I18n.t("analytics.peer_statistics.max_dd")}`,
      dataIndex: "maxDrawDown",
      key: "maxDrawDown",
      sorter: (a, b) =>
        (a.maxDrawDown || -Infinity) - (b.maxDrawDown || -Infinity),
      width: 100,
      render: (text, record) => <Cell value={record.maxDrawDown} />,
    },
    {
      title: `${intervalLabel} ${I18n.t("analytics.peer_statistics.std_dev")}`,
      dataIndex: "standardDeviation",
      key: "standardDeviation",
      sorter: (a, b) => (a.stdDev || -Infinity) - (b.stdDev || -Infinity),
      width: 100,
      render: (text, record) => <Cell value={record.stdDev} />,
    },
    {
      title: `${intervalLabel} ${I18n.t("analytics.peer_statistics.sharpe")}`,
      dataIndex: "sharpeRatio",
      key: "sharpeRatio",
      sorter: (a, b) =>
        (a.sharpeRatio || -Infinity) - (b.sharpeRatio || -Infinity),
      width: 100,
      render: (text, record) => (
        <Cell value={record.sharpeRatio} format="0.00" />
      ),
    },
    {
      title: `${intervalLabel} ${I18n.t("analytics.peer_statistics.beta")}`,
      dataIndex: "beta",
      key: "beta",
      sorter: (a, b) => (a.beta || -Infinity) - (b.beta || -Infinity),
      width: 100,
      render: (text, record) => <Cell value={record.beta} format="0.00" />,
    },
    {
      title: I18n.t("analytics.peer_statistics.earliest_date"),
      dataIndex: "startDate",
      key: "startDate",
      fixed: "right",
      sorter: (a, b) =>
        ((a.startDate && new Date(a.startDate).getTime()) || -Infinity) -
        ((b.startDate && new Date(b.startDate).getTime()) || -Infinity),
      width: 115,
      render: (text, record) => <>{asDisplayDate(record.startDate)}</>,
    },
  ];
}

const Table = ({
  investments,
  statsGroup,
  rangeOffsetLabel,
}: Params): JSX.Element => {
  const baseInvestmentId = investments?.[0]?.id;
  const data: PeerItemStats[] | null = useMemo(() => {
    if (!investments || !statsGroup) return null;

    return investments.map((investment, index) => ({
      investment: {
        id: investment.id,
        name: investment.name,
        isEntitled: !!investment.isEntitled,
      },
      details: {
        list: investment.list?.name,
        strategy: investment.strategy?.name,
        subStrategy: investment.subStrategy?.name,
      },
      compoundReturn1m: statsGroup.compoundReturn1m[index],
      compoundReturn3m: statsGroup.compoundReturn3m[index],
      compoundReturnYtd: statsGroup.compoundReturnYtd[index],
      compoundReturn1yAnnualized: statsGroup.compoundReturn1yAnnualized[index],
      compoundReturn3yAnnualized: statsGroup.compoundReturn3yAnnualized[index],
      compoundReturn5yAnnualized: statsGroup.compoundReturn5yAnnualized[index],
      compoundReturn: statsGroup.compoundReturn[index],
      compoundReturnItdAnnualized:
        statsGroup.compoundReturnItdAnnualized[index],
      maxDrawDown: statsGroup.maxDrawDown[index],
      stdDev: statsGroup.stdDev[index],
      sharpeRatio: statsGroup.sharpeRatio[index],
      beta: statsGroup?.beta[index],
      startDate: statsGroup.startDate[index],
      endDate: statsGroup.endDate[index],
    }));
  }, [investments, statsGroup]);

  const columns = useMemo(
    () => getTableColumns(data || [], rangeOffsetLabel),
    [data, rangeOffsetLabel]
  );

  return (
    <AntdTable
      rowKey={record => record.investment.id}
      scroll={{ x: 1500 }}
      size="small"
      rowClassName={record =>
        (record.investment.id === baseInvestmentId && "bg-blue-grey-50") || ""
      }
      data-cy="peer-table"
      loading={!data}
      columns={columns}
      dataSource={data || []}
      pagination={false}
    />
  );
};

export default Table;
