/* eslint-disable react/jsx-no-undef */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/forbid-prop-types */
import { useApolloClient } from "@apollo/client";
import { InvestmentNotesComponent } from "Components/InvestmentNotes";
import InvestmentTeam from "Components/InvestmentTeam";
import { INVESTMENT_SUMMARY_FRAGMENT } from "Components/graphql/fragments/Investment";
import SummaryContainer from "Components/show/SummaryContainer";
import DocumentsPage from "Components/show/documents";
import InvestmentGallery from "Components/show/overview/investmentGallery";
import { Spinner } from "Components/show/spinner.js";
import { editMode as getEditMode } from "Selectors/investment_edit_mode_selectors";
import PropTypes from "prop-types";
import { Suspense, lazy, useMemo } from "react";
import { useSelector } from "react-redux";
import AddNoteButtonPresenter from "../../../presenters/heading/add_note_button_presenter.js.jsx";
import { PublicAnalytics } from "../analytics";
import PublicInvestmentOverview from "./overview/PublicInvestmentOverview";

// const renderLoader = () => <Spinner />;
function PublicInvestmentSummaryContainer(props) {
  const { investmentId, investmentName, code } = props;
  const Template = useMemo(
    () => lazy(() => import("frontend/src/components/Template")),
    []
  );
  const editMode = useSelector(state => getEditMode(state));

  const client = useApolloClient();
  const { isImpact, firmId, portalSubmitted } = client.readFragment({
    id: `Investment:${investmentId}`,
    fragment: INVESTMENT_SUMMARY_FRAGMENT,
  });

  const tabContents = {
    overview: () => (
      <PublicInvestmentOverview
        investmentId={investmentId}
        typeAbbreviation={code}
      />
    ),

    investment: () => (
      <Suspense fallback={<Spinner />}>
        <Template
          editMode={editMode}
          ownerType="investment"
          ownerId={investmentId}
          name="investment"
          key={`${"investment::"}${investmentId}`}
        />
      </Suspense>
    ),

    operations: () => (
      <Suspense fallback={<Spinner />}>
        <Template
          editMode={editMode}
          ownerType="investment"
          ownerId={investmentId}
          name="operations"
          key={`${"operations::"}${investmentId}`}
        />
      </Suspense>
    ),

    impact: () => (
      <Suspense fallback={<Spinner />}>
        <Template
          editMode={editMode}
          ownerType="investment"
          ownerId={investmentId}
          name="impact"
          key={`${"impact::"}${investmentId}`}
        />
      </Suspense>
    ),

    analytics: () => <PublicAnalytics investmentId={investmentId} />,
    team: () => <InvestmentTeam investmentId={investmentId} />,
    gallery: () => <InvestmentGallery investmentId={investmentId} />,
    notes: () => <InvestmentNotesComponent investmentId={investmentId} />,
    documents: () => (
      <DocumentsPage investmentId={investmentId} firmId={firmId} showChat />
    ),
  };

  return (
    <SummaryContainer
      tabContents={tabContents}
      extraLinks={[<AddNoteButtonPresenter investmentId={investmentId} />]}
      isImpact={isImpact}
      odcSourced={!!portalSubmitted?.migratedAt}
      investmentName={investmentName}
      investmentId={investmentId}
      {...props}
    />
  );
}

PublicInvestmentSummaryContainer.propTypes = {
  investmentId: PropTypes.number.isRequired,
  editMode: PropTypes.bool.isRequired,
  addFlashNotification: PropTypes.func.isRequired,
  investmentName: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default PublicInvestmentSummaryContainer;
