import { useApolloClient, useSubscription } from "@apollo/client";
import { Tooltip } from "antd";
import { useContext, useState } from "react";
import {
  NOTES_REPORT_FILE_QUERY,
  NOTES_REPORT_FILE_SUBSCRIPTION,
} from "./graphql";
import { notesIndexContext } from "./NotesIndex.context";

function randomId() {
  const timestamp = Date.now().toString(36);
  const random = Math.random().toString(36).slice(2);
  return `${timestamp}-${random}`;
}

export const NoteReportButton = () => {
  const { notesFilters: notesFilter } = useContext(notesIndexContext);
  const [sessionId] = useState(randomId);
  const [request, setRequest] = useState<{
    requestId: string;
    reportWindow: Window;
  }>();
  useSubscription(NOTES_REPORT_FILE_SUBSCRIPTION, {
    variables: { sessionId },
    onData({ data: { data } }) {
      console.log("data", data);
      if (!request || !data) return;
      const response = data.reportFileSubscription;
      console.log(request.requestId, response.requestId);
      if (response.requestId !== request.requestId) return;
      if (response.url) {
        request.reportWindow.location = response.url;
      }
      setRequest(undefined);
    },
  });

  const client = useApolloClient();

  return (
    <Tooltip placement="right" title={"Top 100 Notes"}>
      <button
        disabled={!!request}
        onClick={async () => {
          try {
            // We need to open the window ASAP after the click event to avoid the popup blocker
            const reportWindow = window.open("", "_blank");
            if (!reportWindow) {
              throw Error("Failed to open report window");
            }
            const requestId = randomId();
            setRequest({ requestId, reportWindow });
            reportWindow.document.write("Loading...");

            const { data } = await client.query({
              query: NOTES_REPORT_FILE_QUERY,
              variables: { sessionId, requestId, notesFilter },
              fetchPolicy: "no-cache",
            });
            console.log("data", data);
          } catch (error) {
            console.error(error);
            setRequest(undefined);
            throw error;
          }
        }}
        style={{ float: "right" }}
      >
        {request ? (
          <i className="fa fa-spinner fa-spin" />
        ) : (
          <i className="icon icon-print font-15 pb-15 pr-15" />
        )}
      </button>
    </Tooltip>
  );
};
