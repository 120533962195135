import { gql } from "_graphql-types/gql";

export const INVESTMENT_PERFORMANCE_ATTRIBUTE_FRAGMENT = gql(/* GraphQL */ `
  fragment PerformanceInvestmentAttributeFragment on Investment {
    name
    id
    inceptionDate
    assetClass {
      id
      name
    }
    market {
      id
      name
    }
    isManagerUnwillingToProvidePerformance
  }
`);

export const INVESTMENT_TILE = gql(/* GraphQL */ `
  fragment InvestmentTile on Investment {
    id
    name
    inceptionDate
    isImpact
    isInActive
    isPortfolio
    portfolioConfig {
      id
      tileImage {
        id
        signedThumbnailUrl
      }
    }
    assetClass {
      id
      name
    }
    market {
      id
      name
    }
    primaryAssignment
    strategy {
      id
      name
    }
    peStrategy {
      id
      name
    }
    vintageYear
    fields {
      investmentId
      state
      investmentImpactIsImpact {
        value
      }
    }
    investmentTags {
      id
      tag {
        id
        name
      }
    }
    impactTags {
      id
      name
      impactEnumCategoryId
      impactCategory {
        id
        name
      }
    }
    performanceStats {
      id
      compoundReturn1yAnnualized
      startDate
      endDate
    }
    latestInvestmentAUM {
      value
      date
    }
    latestFirmAUM {
      value
      date
    }
    latestStrategyAUM {
      value
      date
    }
    performancePrivate(
      page: { offset: 0, limit: 1 }
      sort: [{ field: asOfDate, order: DESC }]
    ) @connection(key: "performancePrivate") {
      returnYear
      returnQuarter
      netIRR
      tvpi
      dpi
    }
    performanceMTD(
      page: { offset: 0, limit: 1 }
      sort: [{ field: asOfDate, order: DESC }]
      filter: { priorTrackRecord: false, returnType: NET }
    ) {
      returnYear
      returnMonth
      return
    }
    primaryPortfolioManager {
      firmEmployment {
        id
        person {
          id
          lastName
          firstName
          middleName
        }
      }
    }
    committedCapital
    committedCapitalCurrency {
      id
      name
    }
    firm {
      id
      name
      primaryAddress {
        id
        city
        state {
          id
          code
        }
        otherState
        country {
          id
          name
        }
      }
    }
    primaryGeographicFocus {
      id
      name
    }
    access {
      read
      update
      delete
      preview
    }
    accessRequest
  }
`);

export const INVESTMENT_SUMMARY_FRAGMENT = gql(/* GraphQL */ `
  fragment InvestmentSummary on Investment {
    id
    name
    isImpact
    firmId
    isInActive
    portalSubmitted {
      id
      name
      migratedAt
    }
  }
`);

export const EDIT_TOGGLE_INVESTMENT = gql(/* GraphQL */ `
  fragment EditToggle on Investment {
    id
    firmId
    familyId
    market {
      id
    }
    assetClass {
      id
    }
  }
`);

export const PRIVATE_INVESTMENT_PEERS_FRAGMENT = gql(/* GraphQL */ `
  fragment PrivateInvestmentPeersFragment on Investment {
    id
    name
    isEntitled
    marketEnumId
    market {
      id
      name
    }
    vintageYear
    targetRaise
    committedCapital
    committedCapitalCurrency {
      id
      name
    }
  }
`);
