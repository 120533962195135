import Icon from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { NotesFilter, NotesSortEnum, SortInput } from "_graphql-types/graphql";
import {
  DatePicker as AntDatePicker,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Tag,
} from "antd";
import { format } from "date-fns";
import i18n from "i18next";
import { Moment } from "moment";
import momentConfig from "rc-picker/lib/generate/moment";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { NoteReportButton } from "./NoteReportButton";
import { notesIndexContext } from "./NotesIndex.context";

const DatePicker = AntDatePicker.generatePicker(momentConfig);

const NOTE_USERS_QUERY = gql(/* GraphQL */ `
  query noteUsersFilter($filter: InvestmentNoteUsersFilter) {
    investmentNoteUsersList(filter: $filter, page: { limit: 20 }) {
      items {
        id
        name
      }
    }
  }
`);

const en = {
  [NotesSortEnum.Id]: {
    [SortInput.Desc]: "Newest",
    [SortInput.Asc]: "Oldest",
  },
  [NotesSortEnum.TextSearchRank]: {
    [SortInput.Asc]: "Text Search",
    [SortInput.Desc]: "",
  },
  accessLevel: {
    "1": "Public",
    "2": "Organization",
    name: "Access",
  },
};

function NoteTextFilter() {
  const { notesFilters, updateNotesFilter: setNotesFilters } =
    useContext(notesIndexContext);
  const debouncedText = useDebouncedCallback((text: string) => {
    setNotesFilters({
      ...notesFilters,
      text,
    });
  }, 300);

  return (
    <Input
      placeholder="Search by text"
      onInput={e => {
        debouncedText(e.currentTarget.value);
      }}
    />
  );
}

function NotePeopleFilter() {
  const { notesFilters, updateNotesFilter: setNotesFilters } =
    useContext(notesIndexContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);

  const { data, error, loading } = useQuery(NOTE_USERS_QUERY, {
    variables: {
      filter: {
        text: debouncedSearchTerm,
      },
    },
  });

  const updateUsers = (value: string) => {
    const createUsers = notesFilters?.createUsers ?? [];
    const index = createUsers.indexOf(value);
    const newFilter =
      index < 0
        ? [...createUsers, value]
        : createUsers.length === 1
          ? undefined
          : [...createUsers.slice(0, index), ...createUsers.slice(index + 1)];
    setNotesFilters({
      ...notesFilters,
      createUsers: newFilter,
    });
  };

  return (
    <Select
      value={notesFilters?.createUsers ?? []}
      showSearch
      style={{ width: 200 }}
      placeholder="Filter by user"
      mode="multiple"
      maxTagCount={0}
      onSearch={setSearchTerm}
      onSelect={updateUsers}
      onDeselect={updateUsers}
      loading={loading}
    >
      {data?.investmentNoteUsersList.items.map(
        ({ id, name }: { id: string; name: string }) => (
          <Select.Option value={id} key={id}>
            {name}
          </Select.Option>
        )
      )}
    </Select>
  );
}

function NotesDateRangeFilter() {
  const { notesFilters, updateNotesFilter } = useContext(notesIndexContext);

  const updateDates = (dateRange: [Moment | null, Moment | null] | null) => {
    const createDateFilter = (dateRange ?? undefined) && {
      min: dateRange?.[0]?.toDate(),
      max: dateRange?.[1]?.toDate(),
    };
    updateNotesFilter({
      ...notesFilters,
      createDate: createDateFilter,
    });
  };

  return (
    <DatePicker.RangePicker
      allowClear
      allowEmpty={[true, true]}
      onChange={updateDates}
    />
  );
}

function NotesOrder() {
  const { notesSort, updateNotesSort } = useContext(notesIndexContext);

  const updateSort = ({ key }: { key: string }) => {
    const [field, order] = key.split(",", 2);
    updateNotesSort({
      field: field as NotesSortEnum,
      order: order as SortInput,
    });
  };

  const menu = (
    <Menu
      onClick={updateSort}
      selectedKeys={
        (notesSort && [`${notesSort.field},${notesSort.order}`]) || undefined
      }
    >
      <Menu.Item key={`${NotesSortEnum.Id},${SortInput.Desc}`}>
        {en[NotesSortEnum.Id][SortInput.Desc]}
      </Menu.Item>
      <Menu.Item key={`${NotesSortEnum.Id},${SortInput.Asc}`}>
        {en[NotesSortEnum.Id][SortInput.Asc]}
      </Menu.Item>
      <Menu.Item key={`${NotesSortEnum.TextSearchRank},${SortInput.Asc}`}>
        {en[NotesSortEnum.TextSearchRank][SortInput.Asc]}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <div className="main-dropdown">
        <div className="main-dropdown__placeholder">
          {notesSort && en[notesSort.field][notesSort.order]}{" "}
          <i className="main-dropdown__arrow icon icon-arrow" />
        </div>
      </div>
    </Dropdown>
  );
}

function NoteAccessFilter() {
  const { notesFilters, updateNotesFilter } = useContext(notesIndexContext);

  const updateAccess = ({ key }: { key: string }) => {
    const accessLevelId = Number(key);
    const accessLevelIds = notesFilters?.accessLevelIds ?? [];
    const index = accessLevelIds.indexOf(accessLevelId);
    const newFilter =
      index < 0
        ? [...accessLevelIds, accessLevelId]
        : accessLevelIds.length === 1
          ? undefined
          : [
              ...accessLevelIds.slice(0, index),
              ...accessLevelIds.slice(index + 1),
            ];
    updateNotesFilter({
      ...notesFilters,
      accessLevelIds: newFilter,
    });
  };

  const menu = (
    <Menu
      onClick={updateAccess}
      selectedKeys={
        (notesFilters &&
          notesFilters.accessLevelIds &&
          notesFilters.accessLevelIds.map(String)) ||
        []
      }
    >
      <Menu.Item
        key={1}
        icon={<Icon component={() => <i className="icon icon-earth" />} />}
      >
        {en.accessLevel["1"]}
      </Menu.Item>
      <Menu.Item
        key={2}
        icon={<Icon component={() => <i className="icon icon-team" />} />}
      >
        {en.accessLevel["2"]}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <div className="main-dropdown">
        <div className="main-dropdown__placeholder">
          {en.accessLevel.name}
          <i className="main-dropdown__arrow icon icon-arrow" />
        </div>
      </div>
    </Dropdown>
  );
}

function FilterMarks() {
  const { notesFilters, updateNotesFilter } = useContext(notesIndexContext);

  const removeFilter = (filterKey: keyof NotesFilter) => {
    if (notesFilters) {
      const { [filterKey]: _, ...newFilter } = notesFilters;
      updateNotesFilter(newFilter);
    }
  };

  // handle direct note link
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.startsWith("/notes/")) {
      const id = parseInt(location.pathname.slice(7));
      if (isFinite(id)) {
        updateNotesFilter({
          ...notesFilters,
          ids: [id],
        });
        navigate("/notes");
      }
    }
  }, [location.pathname]);

  return (
    <>
      {notesFilters?.ids && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => removeFilter("ids")}
          closable
        >
          Selected Notes ({notesFilters.ids.length})
        </Tag>
      )}

      {notesFilters?.investmentId && (
        <Tag className="ant-tag--blue ant-tag--close">Investment</Tag>
      )}

      {notesFilters?.firmId && (
        <Tag className="ant-tag--blue ant-tag--close">Firm</Tag>
      )}

      {notesFilters?.companyId && (
        <Tag className="ant-tag--blue ant-tag--close">Company</Tag>
      )}

      {notesFilters?.createUsers && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => removeFilter("createUsers")}
          closable
        >
          Users ({notesFilters.createUsers.length})
        </Tag>
      )}

      {notesFilters?.noteTypeIds && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => removeFilter("noteTypeIds")}
          closable
        >
          Note Types ({notesFilters.noteTypeIds.length})
        </Tag>
      )}

      {notesFilters?.accessLevelIds && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => removeFilter("accessLevelIds")}
          closable
        >
          Access ({notesFilters.accessLevelIds.length})
        </Tag>
      )}

      {notesFilters?.text && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => removeFilter("text")}
          closable
        >
          {notesFilters.text}
        </Tag>
      )}

      {notesFilters?.createDate && (
        <Tag
          className="ant-tag--blue ant-tag--close"
          onClose={() => removeFilter("createDate")}
          closable
        >
          Date:{" "}
          {notesFilters.createDate.min
            ? format(notesFilters.createDate.min, "yyyy-MM-dd")
            : ""}
          ...
          {notesFilters.createDate.max
            ? format(notesFilters.createDate.max, "yyyy-MM-dd")
            : ""}
        </Tag>
      )}

      {!!Object.keys(notesFilters ?? {}).filter(
        x => !["firmFilters", "investmentId", "investmentFilters"].includes(x)
      ).length && (
        <Tag
          className="ant-tag--reset"
          style={{ cursor: "pointer" }}
          onClick={() => updateNotesFilter({})}
        >
          {i18n.t("filters.reset_all")}
        </Tag>
      )}
    </>
  );
}

export default function NotesFilters() {
  return (
    <>
      <Row>
        <Col xs={24}>
          <NoteTextFilter />
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col xs={24} md={8}>
          <NotePeopleFilter />
        </Col>
        <Col xs={24} md={8} style={{ textAlign: "center" }}>
          <NotesDateRangeFilter />
        </Col>
        <Col xs={24} md={8} style={{ textAlign: "right" }}>
          <NoteAccessFilter />
          <NotesOrder />
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col xs={23}>
          <FilterMarks />
        </Col>
        <Col xs={1} style={{ textAlign: "right" }}>
          <NoteReportButton />
        </Col>
      </Row>
    </>
  );
}
