import React, { useState, useMemo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { startOfQuarter, lastDayOfQuarter, parseISO } from "date-fns";
import * as types from "_graphql-types/graphql";
import { DateSelector } from "Components/DateRangeSelector";
import classNames from "classnames";
import i18n from "i18next";
import {
  PRIVATE_PEERS_STATS,
  PRIVATE_PEERS_ROOT,
  PRIVATE_PEERS_SUMMARY,
} from "./graphql";
import PrivateRelativePerformanceTable from "../PrivateRelativePerformanceTable";
import { Select, MenuItem } from "@mui/material";
import { Flex, Radio } from "antd";
import { transform } from "lodash";
import Spinner from "frontend/src/components/Spinner";

function PeersStatsSummary({
  statsGroup,
}: {
  statsGroup: types.InvestmentPrivatePeersStatsQuery["investment"]["peersList"]["items"];
}): JSX.Element {
  const { data, error, loading } = useQuery(PRIVATE_PEERS_SUMMARY, {
    variables: {
      peersData: statsGroup.map(investment => {
        return {
          investment_name: investment.name,
          vintage: investment.vintageYear,
          size: investment.committedCapital,
          irr: investment.performancePrivate?.[0]?.netIRR,
          dpi: investment.performancePrivate?.[0]?.dpi,
          tvpi: investment.performancePrivate?.[0]?.tvpi,
          asOfDate: investment.performancePrivate?.[0]?.returnYear
            ? new Date(
                investment.performancePrivate?.[0]?.returnYear,
                investment.performancePrivate?.[0]?.returnQuarter * 3 - 1,
                1
              )
            : null,
          TVPIQuartile:
            investment.performancePrivate?.[0]?.relativeStats
              ?.relativeTVPIQuartile,
          DPIQuartile:
            investment.performancePrivate?.[0]?.relativeStats
              ?.relativeDPIQuartile,
          IRRQuartile:
            investment.performancePrivate?.[0]?.relativeStats
              ?.relativeIRRQuartile,
          grouping: [
            investment.performancePrivate?.[0]?.relativeStats
              ?.privateQuartileGroupCategory?.displayLabel,
            investment.performancePrivate?.[0]?.relativeStats
              ?.privateQuartileGroup?.asOfDate,
          ]
            .filter(Boolean)
            .join(", "),
        };
      }),
    },
    skip: statsGroup.length <= 1,
  });

  // if (error) return <div>{error.message}</div>;
  if (loading) return <Spinner />;
  if (!data) return <> </>;

  return <div data-testid="peer-stat-ai-summary">{data.peersStatSummary}</div>;
}

// eslint-disable-next-line max-lines-per-function
function PrivatePeers({ investmentId }: { investmentId: number }): JSX.Element {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [listType, setListType] = useState<"smart" | "curated">("smart");

  const { data } = useQuery<types.InvestmentPrivatePeersRootQuery>(
    PRIVATE_PEERS_ROOT,
    {
      variables: {
        id: investmentId,
      },
    }
  );

  const [selectedPeerGroupCategoryId, setSelectedPeerGroupCategoryId] =
    useState<number | string>("");

  useEffect(() => {
    if (
      data?.investmentPrivateQuartileGroupCategoryList?.items?.[0]
        ?.privateQuartileGroupCategoryId
    ) {
      setSelectedPeerGroupCategoryId(
        Number(
          data?.investmentPrivateQuartileGroupCategoryList?.items?.[0]
            ?.privateQuartileGroupCategoryId
        )
      );
    }
  }, [data]);

  const [startPeriodDate, endPeriodDate] = useMemo(() => {
    if (
      !data ||
      !data.investment.performanceDataRange ||
      !data.investment.performanceDataRange.length
    )
      return [];
    const latestRecord = data.investment.performanceDataRange[0];
    const firstRecord = data.investment.performanceDataRange.slice(-1)[0];
    const start = new Date(
      firstRecord.returnYear,
      (firstRecord.returnQuarter - 1) * 3,
      1
    );
    const end = new Date(
      latestRecord.returnYear,
      (latestRecord.returnQuarter - 1) * 3,
      1
    );
    const startPeriod = start
      ? parseISO(start.toISOString().slice(0, 10))
      : undefined;
    const endPeriod = end
      ? parseISO(end.toISOString().slice(0, 10))
      : undefined;
    if (!selectedDate) setSelectedDate(endPeriod);
    return [startPeriod, endPeriod];
  }, [data]);

  const { data: statsGroupData } =
    useQuery<types.InvestmentPrivatePeersStatsQuery>(PRIVATE_PEERS_STATS, {
      variables: {
        id: investmentId,
        performancePrivateFilter: {
          asOfDateRange: {
            start: selectedDate && startOfQuarter(selectedDate),
            end: selectedDate && lastDayOfQuarter(selectedDate),
          },
        },
        privateQuartileGroupCategoryId: selectedPeerGroupCategoryId,
        investmentPeersFilter: {
          investmentFilter: {
            active: true,
          },
        },
        investmentSort: [
          {
            field: "name",
            order: types.SortInput.Asc,
          },
        ],
      },
      skip: !selectedDate || !data || !selectedPeerGroupCategoryId,
    });

  const statsGroup = useMemo(() => {
    if (!statsGroupData) return [];

    const { peersList, peersAutoList, ...investment } =
      statsGroupData?.investment ?? {};
    const currentList = listType === "smart" ? peersAutoList : peersList;
    return [...(investment ? [investment] : []), ...(currentList?.items ?? [])];
  }, [data, statsGroupData, listType]);

  return (
    <>
      <div className="summary-heading" data-cy="peer-statistics-private">
        <h3 className="invt-tab__title" id="peer_statistics_private">
          {i18n.t("overview.peer_statistics")}
        </h3>
        <div>
          <Radio.Group
            defaultValue="smart"
            buttonStyle="solid"
            onChange={e => {
              if (e.target.value === "smart") {
                setListType("smart");
              } else {
                setListType("curated");
              }
            }}
          >
            <Radio.Button data-cy="smart" value="smart">
              Smart List
            </Radio.Button>
            <Radio.Button data-cy="curated" value="curated">
              Curated List
            </Radio.Button>
          </Radio.Group>
        </div>
        {statsGroup && (
          <div
            id="peer-statistics__date-selector"
            className={classNames("flex-row", "align-center")}
          >
            <p className="summary-heading__desc">As of</p>
            <DateSelector
              interval="quarter"
              selectedDate={selectedDate}
              setSelectedDate={date => {
                setSelectedDate(date);
              }}
              range={
                startPeriodDate && endPeriodDate
                  ? { start: startPeriodDate, end: endPeriodDate }
                  : undefined
              }
            />
          </div>
        )}
        {!!data?.investmentPrivateQuartileGroupCategoryList?.items?.length && (
          <div
            data-testid="peer-statistics__quartile-selector"
            className={classNames("flex-row", "align-center")}
          >
            <div className="summary-heading__desc">Quartile Grouping</div>

            <Select
              value={selectedPeerGroupCategoryId}
              onChange={e => {
                setSelectedPeerGroupCategoryId(Number(e.target.value));
              }}
              style={{ width: 200 }}
              sx={{
                width: 120,
                height: 30,
                fontSize: "0.875rem",
                padding: "2px",
              }}
              data-testid="peer-statistics__quartile-selector-input"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }}
            >
              {data?.investmentPrivateQuartileGroupCategoryList?.items?.map(
                item => (
                  <MenuItem
                    key={item.privateQuartileGroupCategoryId}
                    value={Number(item.privateQuartileGroupCategoryId)}
                  >
                    {item.privateQuartileGroupCategory?.displayLabel}
                  </MenuItem>
                )
              )}
            </Select>
          </div>
        )}
      </div>
      <PrivateRelativePerformanceTable statsGroupData={statsGroup} />
      <PeersStatsSummary statsGroup={statsGroup} />
    </>
  );
}

export default PrivatePeers;
