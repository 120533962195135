import { gql } from "_graphql-types/gql";

export const PEERS_ROOT = gql(/* GraphQL */ `
  query InvestmentPeersRoot($id: Int!) {
    investment(id: $id) {
      id
      name
      isEntitled
      inceptionDate
      primaryBenchmarkId
      secondaryBenchmarkId
      list {
        id
        name
      }
      strategy {
        id
        name
      }
      subStrategy {
        id
        name
      }
      performancePeriodRange {
        start
        end
      }
    }
  }
`);

export const PEERS_STATS = gql(/* GraphQL */ `
  query PeersStats(
    $id: Int!
    $asOfDate: Date!
    $wrtInvestmentId: Int!
    $rangeOffset: Int
    $rangeUnit: StatsRangeUnit
    $compountReturnAnnualized: Boolean!
    $filter: InvestmentFilterInput
    $includeBenchmarks: Boolean
  ) {
    investment(id: $id) {
      id
      name
      inceptionDate
      peersStats(
        asOfDate: $asOfDate
        wrtInvestmentId: $wrtInvestmentId
        filter: $filter
        includeBenchmarks: $includeBenchmarks
      ) {
        items {
          id
          name
          isEntitled
          strategy {
            id
            name
            code
          }
          subStrategy {
            id
            name
          }
          list {
            id
            name
          }
        }
        stats {
          compoundReturn1m: compoundReturn(annualized: false)
          compoundReturn3m: compoundReturn(rangeOffset: 2, annualized: false)
          compoundReturn1yAnnualized: compoundReturn(
            rangeOffset: 11
            annualized: true
          )
          compoundReturn3yAnnualized: compoundReturn(
            rangeOffset: 35
            annualized: true
          )
          compoundReturn5yAnnualized: compoundReturn(
            rangeOffset: 59
            annualized: true
          )
          compoundReturnYtd: compoundReturn(rangeUnit: Y, annualized: false)
          compoundReturnItdAnnualized: compoundReturn(
            rangeUnit: I
            annualized: true
          )
          compoundReturn: compoundReturn(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: $compountReturnAnnualized
          )
          maxDrawDown(rangeOffset: $rangeOffset, rangeUnit: $rangeUnit)
          beta(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          sharpeRatio(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          stdDev(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          startDate: inceptionDate
          endDate: asOfDate
        }
      }
    }
  }
`);

export const FETCH_INVESTMENTS_FROM_SET = gql(/* GraphQL */ `
  query FetchInvestmentsFromSet($searchFilters: [InvestmentSearchFilters!]) {
    investmentList(searchFilters: $searchFilters) {
      items {
        id
        name
      }
    }
  }
`);

export const INVESTMENT_SET_STATS = gql(/* GraphQL */ `
  query InvestmentSetStats(
    $id: Int!
    $dependentIds: [Int!]!
    $asOfDate: Date!
    $wrtInvestmentId: Int!
    $rangeOffset: Int
    $rangeUnit: StatsRangeUnit
    $compountReturnAnnualized: Boolean!
    $includeBenchmarks: Boolean
  ) {
    investment(id: $id) {
      id
      name
      inceptionDate
      groupStats(
        dependentIds: $dependentIds
        asOfDate: $asOfDate
        wrtInvestmentId: $wrtInvestmentId
        includeBenchmarks: $includeBenchmarks
      ) {
        items {
          id
          name
          isEntitled
          strategy {
            id
            name
            code
          }
          subStrategy {
            id
            name
          }
          list {
            id
            name
          }
        }
        stats {
          compoundReturn1m: compoundReturn(annualized: false)
          compoundReturn3m: compoundReturn(rangeOffset: 2, annualized: false)
          compoundReturn1yAnnualized: compoundReturn(
            rangeOffset: 11
            annualized: true
          )
          compoundReturn3yAnnualized: compoundReturn(
            rangeOffset: 35
            annualized: true
          )
          compoundReturn5yAnnualized: compoundReturn(
            rangeOffset: 59
            annualized: true
          )
          compoundReturnYtd: compoundReturn(rangeUnit: Y, annualized: false)
          compoundReturnItdAnnualized: compoundReturn(
            rangeUnit: I
            annualized: true
          )
          compoundReturn(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: $compountReturnAnnualized
          )
          maxDrawDown(rangeOffset: $rangeOffset, rangeUnit: $rangeUnit)
          beta(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          sharpeRatio(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          stdDev(
            rangeOffset: $rangeOffset
            rangeUnit: $rangeUnit
            annualized: true
          )
          startDate: inceptionDate
          endDate: asOfDate
        }
      }
    }
  }
`);
