import { gql } from "_graphql-types/gql";

export const FETCH_FAMILY_PERFORMANCE = gql(/* GraphQL */ `
  query FamilyPerformance($id: Int!) {
    investment(id: $id) {
      id
      name
      familyId
      ...PrivateInvestmentPeersFragment
      performancePrivate(sort: { field: asOfDate, order: DESC }) {
        investmentId
        returnYear
        returnQuarter
        netIRR
        tvpi
        dpi
        relativeStats {
          relativeTVPIQuartile
          relativeDPIQuartile
          relativeIRRQuartile
          privateQuartileGroupCategoryId
          asOfDate
          privateQuartileGroup {
            id
            asOfDate
          }
          privateQuartileGroupCategory {
            id
            displayLabel
            vintageYear
          }
          privateQuartileGroupId
          investmentId
        }
      }
      family {
        id
        name
        investmentsFamily(sort: [{ field: vintageYear, order: DESC }]) {
          investment {
            id
            name
            isEntitled
            inceptionDate
            vintageYear
            committedCapital
            targetRaise
            performancePrivate(sort: { field: asOfDate, order: DESC }) {
              investmentId
              returnYear
              returnQuarter
              netIRR
              tvpi
              dpi
              relativeStats {
                relativeTVPIQuartile
                relativeDPIQuartile
                relativeIRRQuartile
                privateQuartileGroupCategoryId
                asOfDate
                privateQuartileGroup {
                  id
                  asOfDate
                }
                privateQuartileGroupCategory {
                  id
                  displayLabel
                  vintageYear
                }
                privateQuartileGroupId
                investmentId
              }
            }
          }
        }
      }
      firm {
        id
        name
        investments(sort: [{ field: vintageYear, order: DESC }]) {
          items {
            id
            name
            isEntitled
            inceptionDate
            vintageYear
            ...PrivateInvestmentPeersFragment
            performancePrivate(sort: { field: asOfDate, order: DESC }) {
              investmentId
              returnYear
              returnQuarter
              netIRR
              tvpi
              dpi
              relativeStats {
                relativeTVPIQuartile
                relativeDPIQuartile
                relativeIRRQuartile
                privateQuartileGroupCategoryId
                asOfDate
                privateQuartileGroup {
                  id
                  asOfDate
                }
                privateQuartileGroupCategory {
                  id
                  displayLabel
                  vintageYear
                }
                privateQuartileGroupId
                investmentId
              }
            }
          }
        }
      }
    }
  }
`);
