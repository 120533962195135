import { gql } from "_graphql-types/gql";

export const NOTES_SEARCH = gql(/* GraphQL */ `
  query fetchNotesList(
    $filters: NotesFilter
    $sort: [NotesSort!]
    $page: PageInput
  ) {
    notesList(filter: $filters, sort: $sort, page: $page) {
      items {
        ...NotesShow
      }
      total
      nextPage {
        hasMore
        offset
        limit
      }
    }
  }
`);

export const NOTES_META = gql(/* GraphQL */ `
  query NotesMeta {
    noteCategoryEnum(id: 2) {
      id
      noteCategory
      noteTypes {
        id
        noteType
        noteMetaEnums {
          id
          label
        }
      }
    }
  }
`);

export const NOTES_META_COUNTS = gql(/* GraphQL */ `
  query NotesMetaCounts($filter: NotesFilter) {
    notesMetaCounts(filter: $filter) {
      id
      noteMeta {
        id
        label
      }
      count
    }
  }
`);

export const ADD_NOTES = gql(/* GraphQL */ `
  mutation addNote($input: NotesInput!) {
    addNote(input: $input) {
      ...NotesShow
    }
  }
`);

export const UPDATE_NOTE = gql(/* GraphQL */ `
  mutation updateNote($id: Int!, $input: NotesInput!) {
    updateNote(id: $id, input: $input) {
      ...NotesShow
    }
  }
`);

export const DELETE_NOTE = gql(`
  mutation deleteNote($id: Int!) {
    deleteNote(id: $id) {
      id
    }
  }
`);

export const NOTES_REPORT_FILE_QUERY = gql(/* GraphQL */ `
  query NotesReportFile(
    $sessionId: String!
    $requestId: String!
    $investmentId: Int
    $notesFilter: NotesFilter
  ) {
    notesReportFile(
      sessionId: $sessionId
      requestId: $requestId
      investmentId: $investmentId
      notesFilter: $notesFilter
    ) {
      id
    }
  }
`);

export const NOTES_REPORT_FILE_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription NotesReportFileSubscription($sessionId: String!) {
    reportFileSubscription(sessionId: $sessionId) {
      sessionId
      requestId
      error
      url
    }
  }
`);
