import React, { useCallback } from "react";
import i18n from "i18next";
import {
  downloadSpreadsheet,
  SheetFormat,
  DownloadButtons,
} from "Helpers/downloadSpreadsheet";
import { formatLargeCurrency } from "Helpers/index";
import Table from "./Table";

// eslint-disable-next-line max-lines-per-function
function PrivateRelativePerformanceTable({
  statsGroupData,
}: {
  statsGroupData: any[];
}): JSX.Element {
  const download = useCallback(
    (format: SheetFormat) =>
      statsGroupData.length > 1 &&
      downloadSpreadsheet(
        [
          {
            name: "Sheet 1",
            data: [
              [
                i18n.t("analytics.private_peers.name"),
                i18n.t("analytics.private_peers.vintage_year"),
                i18n.t("analytics.private_peers.size"),
                i18n.t("analytics.private_peers.net_irr"),
                i18n.t("analytics.private_peers.tvpi"),
                i18n.t("analytics.private_peers.dpi"),
                i18n.t("analytics.private_peers.as_of_date"),
                i18n.t("analytics.private_peers.relative_tvpi"),
                i18n.t("analytics.private_peers.relative_irr"),
                i18n.t("analytics.private_peers.relative_dpi"),
                i18n.t("analytics.private_peers.quartile_category"),
                i18n.t("analytics.private_peers.quartile_as_of_date"),
              ],
              ...(statsGroupData ?? []).map(
                ({
                  name,
                  vintageYear,
                  committedCapital,
                  committedCapitalCurrency,
                  performancePrivate,
                  performanceDataRange,
                }) => [
                  name,
                  vintageYear,
                  formatLargeCurrency(
                    committedCapital,
                    committedCapitalCurrency?.name
                  ),
                  performancePrivate?.[0]?.netIRR
                    ? `${performancePrivate?.[0].netIRR.toFixed(1)}%`
                    : undefined,
                  performancePrivate?.[0]?.tvpi,
                  performancePrivate?.[0]?.dpi,
                  performancePrivate[0]
                    ? `Q${performancePrivate[0]?.returnQuarter} ${performancePrivate[0]?.returnYear}`
                    : "",
                  performancePrivate?.[0]?.relativeStats?.relativeTVPIQuartile,
                  performancePrivate?.[0]?.relativeStats?.relativeIRRQuartile,
                  performancePrivate?.[0]?.relativeStats?.relativeDPIQuartile,
                  performancePrivate?.[0]?.relativeStats
                    ?.privateQuartileGroupCategory?.displayLabel,
                  performancePrivate?.[0]?.relativeStats?.privateQuartileGroup
                    ?.asOfDate,
                ]
              ),
            ],
          },
        ],
        `${statsGroupData[0]?.name} Private Performance Statistics`,
        format
      ),
    [statsGroupData]
  );

  return (
    <>
      <Table investments={statsGroupData} />
      <DownloadButtons download={download} />
    </>
  );
}

export default PrivateRelativePerformanceTable;
