/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-lines-per-function */
import { ApolloError, useQuery } from "@apollo/client";
import classNames from "classnames";
import { DateSelector } from "Components/DateRangeSelector";
import { parseISO } from "date-fns";
import { Select, Switch } from "antd";
import {
  DownloadButtons,
  downloadSpreadsheet,
  SheetFormat,
} from "Helpers/downloadSpreadsheet";
import { asDisplayDate } from "Helpers/index";
import i18n from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { snakeCase } from "lodash";
import * as types from "_graphql-types/graphql";
import {
  FETCH_INVESTMENTS_FROM_SET,
  INVESTMENT_SET_STATS,
  PEERS_ROOT,
  PEERS_STATS,
} from "./graphql";
import Table from "./Table";

const MSCI_WORLD_ID = 3108;

const rangeOffsetOptions = [
  {
    label: "Inception",
    value: "inception",
  },
  {
    label: "1 Month",
    value: 0,
  },
  {
    label: "3 Months",
    value: 2,
  },
  {
    label: "6 Months",
    value: 5,
  },
  {
    label: "9 Months",
    value: 8,
  },
  {
    label: "1 Year",
    value: 11,
  },
  {
    label: "18 Months",
    value: 17,
  },
  {
    label: "2 Years",
    value: 23,
  },
  {
    label: "3 Years",
    value: 35,
  },
  {
    label: "4 Years",
    value: 47,
  },
  {
    label: "5 Years",
    value: 59,
  },
  {
    label: "6 Years",
    value: 71,
  },
  {
    label: "7 Years",
    value: 83,
  },
  {
    label: "8 Years",
    value: 95,
  },
  {
    label: "9 Years",
    value: 107,
  },
  {
    label: "10 Years",
    value: 119,
  },
];

export function displayCompoundReturnForSelectedRangeOffset(
  rangeOffsetLabel: string
): boolean {
  return !["1_month", "1_year", "3_months", "ytd"].includes(rangeOffsetLabel);
}

function getLabel(selectedRangeOffset?: number) {
  const value =
    selectedRangeOffset !== undefined ? selectedRangeOffset : "inception";
  const option = rangeOffsetOptions.find(o => o.value === value);
  if (!option) throw new Error(`invalid range offset ${selectedRangeOffset}`);
  return snakeCase(option.label);
}

interface Props {
  investmentId: number;
  investmentSetId?: number;
  includeBenchmarks?: boolean;
}

export interface PeersStats {
  compoundReturn1m: (number | null | undefined)[];
  compoundReturn3m: (number | null | undefined)[];
  compoundReturn1yAnnualized: (number | null | undefined)[];
  compoundReturn3yAnnualized: (number | null | undefined)[];
  compoundReturn5yAnnualized: (number | null | undefined)[];
  compoundReturnYtd: (number | null | undefined)[];
  compoundReturnItdAnnualized: (number | null | undefined)[];
  compoundReturn: (number | null | undefined)[];
  maxDrawDown: (number | null | undefined)[];
  beta: (number | null | undefined)[];
  sharpeRatio: (number | null | undefined)[];
  stdDev: (number | null | undefined)[];
  startDate: (Date | null | undefined)[];
  endDate: (Date | null | undefined)[];
}

type investmentInfo =
  | types.PeersStatsQuery["investment"]["peersStats"]["items"]
  | types.InvestmentSetStatsQuery["investment"]["groupStats"]["items"]
  | undefined;

function useGetStatsData({
  investmentId,
  investmentSetId,
  selectedDate,
  selectedRangeOffset,
  selectedRangeUnit,
  includeBenchmarks = false,
}: {
  investmentId: number;
  investmentSetId?: number;
  selectedDate?: Date;
  selectedRangeOffset?: number;
  selectedRangeUnit?: types.StatsRangeUnit;
  includeBenchmarks?: boolean;
}): [PeersStats, investmentInfo, ApolloError | undefined] {
  const { data: investmentSet } =
    useQuery<types.FetchInvestmentSetInvestmentsQuery>(
      FETCH_INVESTMENTS_FROM_SET,
      {
        variables: {
          searchFilters: investmentSetId
            ? [{ INVESTMENT_SET: { investmentSetId } }]
            : [],
        },
      }
    );

  const { data: investmentSetData, error: investmentSetError } =
    useQuery<types.InvestmentSetStatsQuery>(INVESTMENT_SET_STATS, {
      variables: {
        id: investmentId,
        // component will rerender when the date changes
        asOfDate: selectedDate?.toISOString().slice(0, 10),
        wrtInvestmentId: MSCI_WORLD_ID,
        dependentIds:
          investmentSet?.investmentList?.items
            ?.map(i => i.id)
            .filter(id => id !== investmentId) ?? [],
        rangeOffset: selectedRangeOffset,
        rangeUnit: selectedRangeUnit,
        includeBenchmarks: includeBenchmarks,
        compountReturnAnnualized: !!(
          selectedRangeOffset && selectedRangeOffset > 11
        ),
      },
      skip: !selectedDate || !investmentSetId, // render if using investment set for comparison
    });

  const { data: peersData, error: peersError } =
    useQuery<types.PeersStatsQuery>(PEERS_STATS, {
      variables: {
        id: investmentId,
        // component will rerender when the date changes
        asOfDate: selectedDate?.toISOString().slice(0, 10),
        wrtInvestmentId: MSCI_WORLD_ID,
        rangeOffset: selectedRangeOffset,
        rangeUnit: selectedRangeUnit,
        compountReturnAnnualized: !!(
          selectedRangeOffset && selectedRangeOffset > 11
        ),
        includeBenchmarks: includeBenchmarks,
        filter: {
          active: true,
        },
      },
      skip: !selectedDate || !!investmentSetId, // render if using peers list for comparison
    });

  if (investmentSetId) {
    const statsGroup = investmentSetData?.investment?.groupStats
      ?.stats as PeersStats;
    const investmentInfo = investmentSetData?.investment?.groupStats?.items;

    return [statsGroup, investmentInfo, investmentSetError];
  }

  const statsGroup = peersData?.investment?.peersStats?.stats as PeersStats;
  const investmentInfo = peersData?.investment?.peersStats?.items;

  return [statsGroup, investmentInfo, peersError];
}

function PeerStatistics({
  investmentId,
  investmentSetId,
  includeBenchmarks,
}: Props): JSX.Element {
  const { data: rootData } = useQuery<types.InvestmentPeersRootQuery>(
    PEERS_ROOT,
    {
      variables: { id: investmentId },
    }
  );
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [selectedRangeOffset, setSelectedRangeOffset] = useState<
    number | undefined
  >(35);
  const [selectedRangeUnit, setSelectedRangeUnit] =
    useState<types.StatsRangeUnit>(types.StatsRangeUnit.M);

  const [startPeriodDate, endPeriodDate] = useMemo(() => {
    if (!rootData) return [];
    const { start, end } = rootData.investment.performancePeriodRange ?? {};

    return [start, end].map(d =>
      d ? parseISO(parseISO(d).toISOString().slice(0, 10)) : undefined
    );
  }, [rootData]);

  useEffect(() => {
    setSelectedDate(endPeriodDate);
  }, [endPeriodDate]);

  const [statsGroup, investmentInfo, error] = useGetStatsData({
    investmentId,
    investmentSetId,
    selectedDate,
    selectedRangeOffset,
    selectedRangeUnit,
    includeBenchmarks,
  });

  function handleRangeOffsetChange(rangeOffset?: number | string) {
    if (rangeOffset === undefined) return;

    if (rangeOffset === "inception") {
      setSelectedRangeUnit(types.StatsRangeUnit.I);
      setSelectedRangeOffset(undefined);
    }
    if (typeof rangeOffset === "number" && rangeOffset >= 0) {
      setSelectedRangeOffset(rangeOffset);
      setSelectedRangeUnit(types.StatsRangeUnit.M);
    }
  }

  const rangeOffsetLabel = useMemo(
    () => getLabel(selectedRangeOffset),
    [selectedRangeOffset]
  );

  const download = (format: SheetFormat) =>
    // investments &&
    statsGroup &&
    downloadSpreadsheet(
      [
        {
          name: `Sheet 1`,
          data: [
            [
              i18n.t("analytics.peer_statistics.name"),
              i18n.t("analytics.peer_statistics.list"),
              i18n.t("analytics.peer_statistics.strategy"),
              i18n.t("analytics.peer_statistics.sub_strategy"),
              i18n.t("analytics.peer_statistics.previous_month"),
              i18n.t("analytics.peer_statistics.3_m"),
              i18n.t("analytics.peer_statistics.ytd"),
              i18n.t("analytics.peer_statistics.1_y"),
              i18n.t("analytics.peer_statistics.3_y"),
              i18n.t("analytics.peer_statistics.5_y"),
              ...(displayCompoundReturnForSelectedRangeOffset(rangeOffsetLabel)
                ? [
                    `${i18n.t(`analytics.peer_statistics.${rangeOffsetLabel}`)} c.`,
                  ]
                : []),
              i18n.t("analytics.peer_statistics.itd_anl"),
              `${i18n.t(`analytics.peer_statistics.max_dd`)} ${i18n.t(
                `analytics.peer_statistics.${rangeOffsetLabel}`
              )}`,
              `${i18n.t(`analytics.peer_statistics.std_dev`)} ${i18n.t(
                `analytics.peer_statistics.${rangeOffsetLabel}`
              )}`,
              `${i18n.t(`analytics.peer_statistics.sharpe`)} ${i18n.t(
                `analytics.peer_statistics.${rangeOffsetLabel}`
              )}`,
              `${i18n.t(`analytics.peer_statistics.beta`)} ${i18n.t(
                `analytics.peer_statistics.${rangeOffsetLabel}`
              )}`,
              i18n.t("analytics.peer_statistics.earliest_date"),
            ],
            ...(investmentInfo || []).map(
              ({ name, strategy, list, subStrategy }, iinvestment) => [
                name,
                list?.name ?? "-",
                strategy?.name,
                subStrategy?.name,
                statsGroup.compoundReturn1m[iinvestment] ?? "-",
                statsGroup.compoundReturn3m[iinvestment] ?? "-",
                statsGroup.compoundReturnYtd[iinvestment] ?? "-",
                statsGroup.compoundReturn1yAnnualized[iinvestment] ?? "-",
                statsGroup.compoundReturn3yAnnualized[iinvestment] ?? "-",
                statsGroup.compoundReturn5yAnnualized[iinvestment] ?? "-",
                ...(displayCompoundReturnForSelectedRangeOffset(
                  rangeOffsetLabel
                )
                  ? [statsGroup.compoundReturn[iinvestment] ?? "-"]
                  : []),
                statsGroup.compoundReturnItdAnnualized[iinvestment] ?? "-",
                statsGroup.maxDrawDown[iinvestment] ?? "-",
                statsGroup.stdDev[iinvestment] ?? "-",
                statsGroup.sharpeRatio[iinvestment] ?? "-",
                statsGroup?.beta[iinvestment] ?? "-",
                asDisplayDate(statsGroup.startDate[iinvestment] ?? "-"),
              ]
            ),
          ],
        },
      ],
      `${rootData?.investment.name} Peer Statistics`,
      format
    );

  if (error) {
    return <>{error.message}</>;
  }

  return (
    <>
      <div className="summary-heading" data-cy="peer-statistics">
        <h3 className="invt-tab__title" id="peer_statistics">
          {i18n.t("overview.peer_statistics")}
        </h3>
        <div className="summary-heading__end-col">
          {statsGroup && (
            <div
              id="peer-statistics__date-selector"
              className={classNames("flex-row", "align-center")}
            >
              <p className="summary-heading__desc">As of</p>
              <DateSelector
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                range={
                  startPeriodDate && endPeriodDate
                    ? { start: startPeriodDate, end: endPeriodDate }
                    : undefined
                }
              />
            </div>
          )}
          {statsGroup && (
            <div
              id="peer-statistics__rangeOffset-selector"
              className={classNames("flex-row", "align-center")}
            >
              <p className="summary-heading__desc">
                {i18n.t("analytics.peer_statistics.range_offset")}
              </p>
              <Select
                onChange={(value?: number | string) =>
                  handleRangeOffsetChange(value)
                }
                options={rangeOffsetOptions}
                value={
                  selectedRangeOffset !== undefined
                    ? selectedRangeOffset
                    : "inception"
                }
                style={{ width: 120 }}
              />
            </div>
          )}
        </div>
      </div>
      <Table
        investments={investmentInfo}
        statsGroup={statsGroup}
        rangeOffsetLabel={rangeOffsetLabel}
      />
      <DownloadButtons {...{ download }} />
    </>
  );
}

export default PeerStatistics;
