import { formatLargeCurrency } from "Helpers/index";
import React, { useMemo } from "react";
import * as types from "_graphql-types/graphql";
import { Table as AntdTable } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import InvestmentLink from "Components/InvestmentLink";
import { format } from "date-fns";
import { Tooltip } from "@material-ui/core";
import { Box, Typography } from "@mui/material";

interface Params {
  investments?: types.InvestmentPrivatePeersStatsQuery["investment"]["peersList"]["items"];
}

type PeerItemStats = {
  investment: {
    id: number;
    name?: string | null;
    isEntitled: boolean;
    vintageYear?: number | null;
    targetRaise?: number | null;
    committedCapital?: number | null;
    committedCapitalCurrency?: types.CurrencyDenominationEnum | null;
    maxPerformanceYear?: number | null;
    maxPerformanceQuarter?: number | null;
  };
  netIRR?: number | null;
  tvpi?: number | null;
  dpi?: number | null;
  returnYear?: number | null;
  returnQuarter?: number | null;
  relativeStats?: {
    relativeTVPIQuartile?: number | null;
    relativeIRRQuartile?: number | null;
    relativeDPIQuartile?: number | null;
    privateQuartileGroupCategory?: {
      displayLabel?: string | null;
      vintageYear?: number | null;
    } | null;
    privateQuartileGroup?: {
      id?: number | null;
      asOfDate?: number | null;
    } | null;
  } | null;
};
const { Column, ColumnGroup } = AntdTable;

const Table = ({ investments }: Params): JSX.Element => {
  const baseInvestmentId = investments?.[0]?.id;
  const data: PeerItemStats[] | null = useMemo(() => {
    if (!investments) return null;
    return investments.map(investment => ({
      investment: {
        id: investment.id,
        name: investment.name,
        isEntitled: !!investment.isEntitled,
        vintageYear: investment.vintageYear,
        targetRaise: investment.targetRaise,
        committedCapital: investment.committedCapital,
        committedCapitalCurrency: investment.committedCapitalCurrency,
        ...(investment.performanceDataRange?.length && {
          maxPerformanceYear: investment.performanceDataRange[0].returnYear,
          maxPerformanceQuarter:
            investment.performanceDataRange[0].returnQuarter,
        }),
      },
      ...(investment.performancePrivate?.length && {
        netIRR: investment.performancePrivate[0].netIRR,
        tvpi: investment.performancePrivate[0].tvpi,
        dpi: investment.performancePrivate[0].dpi,
        returnYear: investment.performancePrivate[0].returnYear,
        returnQuarter: investment.performancePrivate[0].returnQuarter,
        relativeStats: investment.performancePrivate[0].relativeStats,
      }),
    }));
  }, [investments]);

  return (
    <AntdTable
      rowKey={record => record.investment.id}
      scroll={{ x: 1500 }}
      size="small"
      rowClassName={record =>
        (record.investment.id === baseInvestmentId && "bg-blue-grey-50") || ""
      }
      data-cy="relative-performance-table-private"
      loading={!data || data.length === 0}
      dataSource={data || []}
      pagination={false}
    >
      <Column
        title={I18n.t("analytics.private_peers.name")}
        dataIndex={"investment"}
        key="investment"
        width={65}
        render={(text, record: PeerItemStats) => {
          const { id, name, isEntitled } = record.investment;
          return (
            <InvestmentLink
              className="fund-link-wrapper"
              investmentId={id}
              isEntitled={isEntitled}
            >
              {name}
            </InvestmentLink>
          );
        }}
        fixed="left"
        filterMode="tree"
        filters={(data || []).slice(1, -1).map(datum => ({
          text: datum.investment.name,
          value: datum.investment.id,
        }))}
        onFilter={(value, record: PeerItemStats) => {
          if (!data) return false;
          if (record.investment.id === data[0].investment.id) return true;
          return record.investment.id === value;
        }}
      />
      <Column
        title={I18n.t("analytics.private_peers.vintage_year")}
        dataIndex="investment.vintageYear"
        key="vintageYear"
        width={20}
        sorter={(a: PeerItemStats, b: PeerItemStats) =>
          (a.investment.vintageYear ?? -Infinity) -
          (b.investment.vintageYear ?? -Infinity)
        }
        render={(text, record: PeerItemStats) => (
          <div>{record.investment.vintageYear}</div>
        )}
        align="right"
      />

      <Column
        title={I18n.t("analytics.private_peers.size")}
        dataIndex="investment.targetRaise"
        key="size"
        width={20}
        sorter={(a: PeerItemStats, b: PeerItemStats) =>
          (a.investment.committedCapital ?? -Infinity) -
          (b.investment.committedCapital ?? -Infinity)
        }
        render={(text, record: PeerItemStats) => (
          <div>{formatLargeCurrency(record?.investment?.committedCapital)}</div>
        )}
        align="right"
      />
      <ColumnGroup title={"Performance"}>
        <Column
          title={I18n.t("analytics.private_peers.net_irr")}
          dataIndex="netIRR"
          key="netIRR"
          width={20}
          sorter={(a: PeerItemStats, b: PeerItemStats) =>
            (a.netIRR ?? -Infinity) - (b.netIRR ?? -Infinity)
          }
          render={(text, record: PeerItemStats) => (
            <div>
              {typeof record.netIRR === "number" &&
                `${record.netIRR.toFixed(1)}%`}
            </div>
          )}
          align="right"
        />

        <Column
          title={I18n.t("analytics.private_peers.tvpi")}
          dataIndex="tvpi"
          key="tvpi"
          width={20}
          sorter={(a: PeerItemStats, b: PeerItemStats) =>
            (a.tvpi ?? -Infinity) - (b.tvpi ?? -Infinity)
          }
          render={(text, record: PeerItemStats) => (
            <div>
              {typeof record.tvpi === "number" && `${record.tvpi.toFixed(1)}x`}
            </div>
          )}
          align="right"
        />

        <Column
          title={I18n.t("analytics.private_peers.dpi")}
          dataIndex="dpi"
          key="dpi"
          width={20}
          sorter={(a: PeerItemStats, b: PeerItemStats) =>
            (a.dpi ?? -Infinity) - (b.dpi ?? -Infinity)
          }
          render={(text, record: PeerItemStats) => (
            <div>
              {typeof record.dpi === "number" && `${record.dpi.toFixed(1)}x`}
            </div>
          )}
          align="right"
        />

        <Column
          title={I18n.t("analytics.private_peers.as_of_date")}
          key="asOfDate"
          width={20}
          render={(text, record: PeerItemStats) => (
            <div>
              {record.returnYear && record.returnQuarter
                ? `Q${record.returnQuarter} ${record.returnYear}`
                : ""}
            </div>
          )}
          align="right"
        />
      </ColumnGroup>

      <ColumnGroup title={"Quartile"}>
        <Column
          title={I18n.t("analytics.private_peers.net_irr")}
          key="relativeIRRQuartile"
          width={20}
          sorter={(a: PeerItemStats, b: PeerItemStats) =>
            (a.relativeStats?.relativeIRRQuartile ?? -Infinity) -
            (b.relativeStats?.relativeIRRQuartile ?? -Infinity)
          }
          render={(text, record: PeerItemStats) => (
            <div>{record?.relativeStats?.relativeIRRQuartile}</div>
          )}
          align="right"
        />

        <Column
          title={I18n.t("analytics.private_peers.tvpi")}
          key="relativeTVPIQuartile"
          width={20}
          sorter={(a: PeerItemStats, b: PeerItemStats) =>
            (a.relativeStats?.relativeTVPIQuartile ?? -Infinity) -
            (b.relativeStats?.relativeTVPIQuartile ?? -Infinity)
          }
          render={(text, record: PeerItemStats) => (
            <div>{record?.relativeStats?.relativeTVPIQuartile}</div>
          )}
          align="right"
        />

        <Column
          title={I18n.t("analytics.private_peers.dpi")}
          key="relativeDPIQuartile"
          width={20}
          sorter={(a: PeerItemStats, b: PeerItemStats) =>
            (a.relativeStats?.relativeDPIQuartile ?? -Infinity) -
            (b.relativeStats?.relativeDPIQuartile ?? -Infinity)
          }
          render={(text, record: PeerItemStats) => (
            <div>{record?.relativeStats?.relativeDPIQuartile}</div>
          )}
          align="right"
        />
        <Column
          title={I18n.t("analytics.private_peers.quartile_grouping")}
          key="privateQuartileGroupCategory"
          width={30}
          render={(text, record: PeerItemStats) => {
            const { privateQuartileGroupCategory, privateQuartileGroup } =
              record?.relativeStats || {};
            const displayLabel =
              privateQuartileGroupCategory?.displayLabel || "";
            const asOfDate = privateQuartileGroup?.asOfDate;

            // Format the asOfDate, defaulting to "N/A" if undefined or invalid
            const formattedDate = asOfDate
              ? format(new Date(asOfDate), "yyyy MMM")
              : "N/A";

            // Create the concise string to display in the cell

            const conciseDisplay = displayLabel
              ? `${displayLabel.split(" - ")[1] || ""}`
              : "N/A";

            // Full label for Tooltip (more detailed)
            const fullLabel = displayLabel
              ? `${displayLabel} - ${formattedDate}`
              : "N/A";

            return (
              <Box
                sx={{
                  maxWidth: "150px", // Limit the max width for compact display
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography variant="body2" noWrap>
                  <Tooltip title={fullLabel} arrow>
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      {conciseDisplay}
                    </span>
                  </Tooltip>
                </Typography>
              </Box>
            );
          }}
          align="right"
        />
      </ColumnGroup>
    </AntdTable>
  );
};
export default Table;
